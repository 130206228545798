import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import {
    TemplateButton,
    TemplateButtonProps
} from 'common/components/organisms/TemplateButton'
import { Ticklist, TicklistProps } from 'common/components/organisms/Ticklist'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface ProductClearInvestProps {
    alerts: AlertsProps
    benefits: BenefitsProps
    jumbotron: JumbotronProps
    featureList: FeatureListProps
    layout: LayoutProps
    resources: ResourcesProps
    ticklist: TicklistProps
    button: TemplateButtonProps
}

export const ProductClearInvest: React.FC<ProductClearInvestProps> = ({
    alerts,
    benefits,
    button,
    resources,
    jumbotron,
    layout,
    featureList,
    ticklist
}) => {
    const desktop = useDesktop()

    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <Spacer variant={desktop ? 'lg' : 'md'} />
                <FeatureList variant='left' {...featureList} />
                <Spacer variant={desktop ? 'lg' : 'sm'} />
            </Wrapper>
            <Ticklist {...ticklist} />
            <Wrapper>
                <Spacer />
                <Resources {...resources} />
                <Spacer />
                <Benefits {...benefits} iconSize='lg' />
                {button.label && (
                    <>
                        <Spacer variant='sm' />
                        <TemplateButton {...button} />
                    </>
                )}
                <Spacer />
            </Wrapper>
            <Alerts {...alerts} />
        </Layout>
    )
}
