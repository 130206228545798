import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import Tick from './Tick_Outline_Simple.svg'

export interface TicklistItemProps {
    text: string
}

export const TicklistItem: React.FC<TicklistItemProps> = ({ text }) => {
    return (
        <Box alignItems='flex-start' display='flex' mb={6} color='#615A80'>
            <img
                alt='Tick Outline Simple'
                src={Tick}
                style={{ marginRight: 24 }}
            />
            <Text label={text} />
        </Box>
    )
}
