import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { getResourcesData } from 'common/components/organisms/Resources'
import { ProductClearInvest } from 'common/components/templates/ProductClearInvest'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ProductClearInvestPageProps {
    pageContext: any
}

export const ProductClearInvestPage: React.FC<ProductClearInvestPageProps> = ({
    pageContext
}) => {
    return <ProductClearInvest {...getProductClearInvestData(pageContext)} />
}

export default ProductClearInvestPage

export const getProductClearInvestData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    button: {
        analyticsId: data.button_c9ad60e__analytics_id || '',
        label: data.button_c9ad60e__text,
        url: data.button_c9ad60e__url,
        endIcon: data.button_c9ad60e__icon
    },
    alerts: {
        alerts: data.alerts__alerts.map((alert: any) => alert.text)
    },
    benefits: {
        title: data.threecards__title,
        subtitle: data.benefits_subtitle,
        benefits: data.threecards__cards.map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            },
            icon: card.icon,
            text: card.text,
            title: card.title
        }))
    },
    featureList: {
        featureTitle: data.featurelist2__title,
        title: data.featurelist2__features_title,
        subtitle: data.featurelist2__subtitle,
        image: data.featurelist2__image,
        features: data.featurelist2__features,
        text: data.featurelist2__text
    },
    resources: getResourcesData(data),
    ticklist: {
        title: data.ticklist__title,
        text: data.ticklist__text,
        ticklistTitle: data.ticklist__ticklist_title,
        ticks: (data.ticklist__ticks || []).map(
            (tick: { text: string }) => tick.text
        )
    }
})
