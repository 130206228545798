import { Box, Grid } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { TicklistItem } from './TicklistItem'

export interface TicklistProps {
    title: string
    text: string
    ticklistTitle: string
    ticks: string[]
}

export const Ticklist: React.FC<TicklistProps> = ({
    text,
    title,
    ticklistTitle,
    ticks = []
}) => {
    const desktop = useDesktop()

    return (
        <Box
            mx={desktop ? 6 : 0}
            my={6}
            display='flex'
            flexDirection='column'
            style={{
                background:
                    'radial-gradient(71.23% 43.94% at 10.65% 72.43%, rgba(247, 247, 249, 0.8) 0%, rgba(247, 247, 249, 0) 100%), #EBEDF7'
            }}
        >
            <Wrapper>
                <Spacer variant={desktop ? 'lg' : 'md'} />
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <Text
                            box={{ mb: 7 }}
                            html
                            label={ticklistTitle}
                            variant='h5'
                        />
                        {ticks.map(tick => (
                            <TicklistItem key={tick} text={tick} />
                        ))}
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={5}>
                        <Text
                            box={{ mb: 7 }}
                            html
                            label={title}
                            variant={desktop ? 'h4' : 'h2'}
                        />
                        <Text
                            box={{ mb: 7 }}
                            html
                            label={text}
                            variant='body1'
                        />
                    </Grid>
                </Grid>
            </Wrapper>
            <Spacer variant={desktop ? 'lg' : 'md'} />
        </Box>
    )
}
